import React from 'react'

// import Navbar from '../Navbar/Navbar'
import SliderProduct from './ProductSlider/SliderProduct';
import Home from './HomeProject/Home';
import ProjectCustomer from './ProjectCustomer/Customer';

export default function HomeMain() {
  return (
    <>
      {/* <Navbar /> */}
      <SliderProduct />
      <Home/>
      <ProjectCustomer/>
    </>
  )
}
 