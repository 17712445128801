import React, { useRef } from 'react'
import './Contact.css'
import img1 from '../../assets/background/Contact1.png'
import emailjs from '@emailjs/browser'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import CommonImage from '../Common/CommonImage/CommonImage'

export default function Contactpart2() {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_t8cnbkh', 'template_embrtmd', e.target, 'QxoHaRkDQ3EeE0bVy')
            .then((result) => {
                toast.success("Query Sended Successfully")
            }, (error) => {
                toast.error("Query Not Sended")
            });
        e.target.reset()
    };
    return (
        <>
        <div class="containerblock">
        <CommonImage img ={img1} />
        <div class="text-block">
        {/* <h1>GET IN TOUCH</h1> */}
        {/* <p>Need to get in touch with us? Either fill out the form with your inquiry or find the department email you'd  like to contact below.</p> */}
    </div>
</div>
        
            <div className="ContactMainContainer">
            <div className='contactbar'></div>     
                <div className="headingContactMain">CONTACT US</div>
                <div className="container-contactpart2">
                <div className="contact-map">
                        <iframe title="myFrame" scrolling="no" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27217.4379269629!2d74.32398946451448!3d31.491742957433413!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3919056263ef4c05%3A0x85bec1e0aca31a88!2s4%2F17%2C%20Block%20M%20Gulberg%20III%2C%20Lahore%2C%20Punjab!5e0!3m2!1sen!2s!4v1704542308553!5m2!1sen!2s"></iframe> <script type='text/javascript' src='https://embedmaps.com/google-maps-authorization/script.js?id=ee9f20cc9376934fce3c0d62d5c396baab68adac'></script>
                    </div>

                    <div className='contactformmain'>
                    <div className='contactbar2'></div>  
                        <div className="headingcontactform">FOR QUERIES</div>
                        <form ref={form} onSubmit={sendEmail} className="contact-form">
                            <input className='input' type="text" name='name' id='name' placeholder='Full Name' required/>
                            <input className='input' type="email" name='email' id='email' placeholder='Email' required/>
                            <input className='input' type="phone" name='phone' id='phone' placeholder='Phone' required/>
                            <input className='input' type="text" name='address' id='address' placeholder='Address' required/>
                            <textarea className='input' name="query" id="query" cols="30" rows="4" placeholder='Your query' required></textarea>
                            <button className="btn-contact" type='submit'>Submit Query</button>
                            <ToastContainer />
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}