import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css'; 
import img1 from '../../../assets/background/Home.jpg'

const Home = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/project');
  };

  return (
    <div className="home-container">
      <div className="home-image">
        <img src={img1} alt="Uploading" />
      </div>
      <div className="home-text">
        <h1>Welcome to HUNCH Automation</h1>
        <p className="para">
          A rising tech player in Industrial Automation & Wireless solutions.
          We turn groundbreaking ideas into reality.
          Join us in boosting your productivity and profitability together!
        </p>
 
        <button className="project-btn" onClick={handleButtonClick}> Visit Our Projects </button>
      </div>
    </div>
  );
};

export default Home;
